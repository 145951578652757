<template>
  <div>
    <dashboard-page-title  :showAddBtn="false" >طلبات سحب التجار</dashboard-page-title>
    <main-table :baseURL="SFZ_API_LINK"  :fields="fields" list_url="admin/wallets/withdraw-requests/merchants"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SFZ_API_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      SFZ_API_LINK,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'اسم التاجر', key: 'user.name', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'المبلغ الاجمالى', key: 'amount', class: 'text-right' },
        { label: 'تاريخ الانشاء', key: 'created_at', class: 'text-right', type: 'date' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              icon: 'ri-edit-line',
              color: 'primary-light',
              text: 'عرض',
              ifNavigate: true,
              routeParams: ['id'],
              routeName: 'viewWithRequest'
            }
          ]
        }
      ]
    }
  },
  methods: {}
}
</script>
