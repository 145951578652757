import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/modules/auth/auth.routes';
import dashboardRoutes from '../modules/dashboard/dashboard.routes';
import marketPlaceRoutes from '../modules/marketPlace/marketPlace.routes';
import transactions from '@/modules/transactions/transactions.routes';
import supportRoutes from '@/modules/support/support.routes';
import countriesRoutes from '@/modules/countries/countries.routes';
import citiesRoutes from '@/modules/cities/cities.routes';
import areasRoutes from '@/modules/areas/areas.routes';
import categoriesRoutes from '@/modules/categories/categories.routes';
import storeCategoriesRoutes from '@/modules/storeCategories/storeCategories.routes';
import unitsRoutes from '@/modules/units/units.routes';
import contactusRoutes from '@/modules/contacts/contacts.routes';
import shippingRoutes from '../modules/shipping/shipping.routes';
/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage');

Vue.use(VueRouter);

const routes = [
  ...auth,
  ...dashboardRoutes,
  ...marketPlaceRoutes,
  ...transactions,
  ...supportRoutes,
  ...countriesRoutes,
  ...citiesRoutes,
  ...areasRoutes,
  ...categoriesRoutes,
  ...storeCategoriesRoutes,
  ...unitsRoutes,
  ...contactusRoutes,
  ...shippingRoutes,
  {
    path: '*',
    name: 'errorPage',
    meta: { name: 'dashboard', userType: 'both' },
    component: ErrorPage
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});
/* router.beforeEach((to, from, next) => {
  if (to.meta.userType === 'both') {
    next()
  } else if (securityFunctions.methods.isAdmin(to.meta.userType)) {
    if (securityFunctions.methods.hasPer(to.meta.permission)) {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  } else {
    if (securityFunctions.methods.hasServiceType(to.meta.serviceTypes) || to.meta.serviceTypes === 'all') {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  }
}) */
router.beforeEach((to, from, next) => {
  const publicPages = [ 'login' ];
  if (!localStorage.getItem('userToken') && !publicPages.includes(`${ to.name }`)) {
    return next({
      name: 'login'
    });
  } else if (localStorage.getItem('userToken') && publicPages.includes(`${ to.name }`)) {
    return next({ name: 'index' });
  } else {
    next();
  }
});

export default router;
