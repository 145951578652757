<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
      بيانات الطلب
    </dashboard-page-title>
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-row>
            <b-col md="12">
              <div>
                <b-card class="iq-border-radius-10 mb-3">
                  <template v-slot:header>
                    <h5 class="text-primary"><i class="las la-file-alt"></i> معلومات المستخدم</h5>
                  </template>
                  <b-row>
                    <b-col  cols="12" >
                      <input-form
                          class="mb-3 joining-label"
                          v-model="item.customer.name"
                          validate="required"
                          name="id"
                          :disabled="true"
                          :placeholder="$t('main.name')"
                          :label="$t('main.name')"
                          readonly
                      />
                    </b-col>
                    <b-col  md="6">
                      <input-form
                          class="mb-3 joining-label"
                          v-model="item.customer.phone"
                          :validate="'required|numeric'"
                          name="id"
                          :disabled="true"
                          placeholder="Ex: 0109564546" :label="$t('main.phone')"
                          readonly
                      />
                    </b-col>
                    <b-col  md="6">
                      <input-form
                          class="mb-3 joining-label"
                          v-model="item.customer.national_id"
                          :validate="'required|numeric'"
                          name="id"
                          :disabled="true"
                          placeholder="Ex: 454846511154" :label="$t('main.national_id')"
                          readonly
                      />
                    </b-col>
                    <b-col  md="6">
                      <input-form
                          class="mb-3 joining-label"
                          v-model="item.customer.email"
                          :validate="'required|email'"
                          name="id"
                          :disabled="true"
                          :placeholder="$t('main.email')"
                          :label="$t('main.email')"
                          readonly
                      />
                    </b-col>
                    <b-col md="6">
                      <main-select
                        :options="
                        // ['pending','accepted','denied']
                        [
                          { name: 'معلق', value: 'pending' },
                          { name: 'مقبول', value: 'accepted' },
                          { name: 'مرفوض', value: 'denied'}
                        ]
                        "
                                  :reduce="item => item.value"

                        v-model="item.new_status"
                        class="bg-select"
                        label="name"
                        :labelTitle="$t('main.status')"
                        :placeholder="$t('main.status')"
                      />
                    </b-col>
                  </b-row>
                </b-card>
                <b-card class="iq-border-radius-10 mb-3">
                  <template v-slot:header>
                    <h5 class="text-primary"><i class="las la-store"></i> معلومات الطلب</h5>
                  </template>
                  <b-row>
                    <b-col  cols="12">
                      <input-form
                          class="mb-3 joining-label"
                          :value="item.amount"
                          :disabled="true"
                          :placeholder="$t('main.amount')"
                          :label="$t('main.amount')"
                          :type="'number'"
                          readonly
                      />
                    </b-col>
                    <b-col md="6">
                      <span>
                        {{ $t('main.created_at') }} :
                      </span>
                      {{ new Date(item.created_at).toLocaleDateString() }}
                    </b-col>
                    <b-col md="6">
                      <span>
                        {{ $t('main.updated_at') }} :
                      </span>
                      {{ new Date(item.updated_at).toLocaleDateString() }}
                    </b-col>
                  </b-row>
                </b-card>
                <b-card class="iq-border-radius-10 mb-3">
                  <template v-slot:header>
                    <h5 class="text-primary"><i class="las la-image"></i>  الصور</h5>
                  </template>
                  <b-row>
                    <b-col md="12" class="mb-4">
                      <cropper-images
                          :label="$t('main.invoiceImage')"
                          nameOfImage="image.jpg"
                          @cropper-save="saveLogoImage"
                          :progressLoading="progressLoading"
                          :multi="false"
                          :showProgress="progressLogo"
                          :imageUrl="item.invoice_path"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </b-col>
            <b-col cols="12" v-if="!disabled">
              <b-button variant="primary" type="submit" :disabled="progressLogo">
                <span v-if="progressLogo">
                  <spinner-loading class="d-inline"></spinner-loading>
                  <span>{{ $t('main.loading') }}</span>
                </span>
                <template v-else>
                {{ $t('main.save') }}
              </template>

              </b-button>
            </b-col>
          </b-row>
        </form>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import merchants from '../../services/marketPlace'
import { SFZ_API_LINK } from '@/config/constant'

export default {
  components: { },
  mounted () {
    core.index()
    merchants.getWithDrawalRequestDetails(this.$route.params.id).then(res => {
      this.item = res.data
      if (res.data.merchant != null) {
        this.item.customer = res.data.merchant
      } else if (res.data.user != null) {
        this.item.customer = res.data.user
      }
      this.item.invoice_path = res.data.invoice.invoice_file
      // if (this.item.user == null) {
      //   this.item.user = res.data.customer
      // }
      this.item.created_at = new Date(this.item.created_at).toLocaleDateString()
      this.item.updated_at = new Date(this.item.updated_at).toLocaleDateString()
    })
  },
  data () {
    return {
      SFZ_API_LINK,
      item: {
        amount: 0,
        status: 'pending',
        created_at: '',
        updated_at: '',
        user: null,
        customer: {
          name: '',
          phone: '',
          national_id: '',
          email: ''
        },
        invoice_path: '',
        new_status: 'pending'
      },
      progressLoading: false,
      progressLogo: false
    }
  },
  watch: {
    item: {
      handler (val) {
        this.item.new_status = val.status
      },
      deep: true
    }
  },
  methods: {
    saveLogoImage (file) {
      this.progressLogo = true
      const formData = new FormData()
      formData.append('image', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressLoading = percent
        }
      }
      this.commonUploadImages(formData, options, 'admin/upload/withdraw-request').then(res => {
        this.item.invoice_path = res.data.preview
        this.showSuccessUploadFile()
      }).finally(() => {
        this.progressLoading = 100
        this.progressLogo = false
      })
    },
    handleSubmit (onSubmit) {
      this.progressLoading = true
      merchants.updateWithDrawalRequest(this.$route.params.id, this.item).then(res => {
        core.showSnackbar('success', res.data.message)
        // this.$router.push({ name: 'userWithdrawalRequests' })
        this.$router.go(-1)
      }).finally(() => {
        this.progressLoading = false
      })
    }
  }

}
</script>

<style>
  .vs__dropdown-toggle {
    background: #fff !important;
    border-radius: 10px !important;
  }
</style>
