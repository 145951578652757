// Layout
import sellerWithdrawalsRequestsIndex from './views/withdrawals/sellerWithdrawalsRequestsIndex.vue'
import userWithdrawalsRequestsIndex from './views/withdrawals/userWithdrawalsRequestsIndex.vue'
import withDrawalRequestDetails from './views/withdrawals/withDrawalRequestDetails.vue'
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const merchant = () => import('./views/index')
const pendingMerchant = () => import('./views/pendingMerchant')
const addMerchant = () => import('./views/addMerchant')
// const allTax = () => import('./tax/views/index')
const settings = () => import('./settings/views/settings')
const customers = () => import('./views/customers')
const reviews = () => import('./views/reviews')
const orders = () => import('./views/orders')

// start Routes
export default [
  {
    path: '/marketplace',
    name: 'merchant',
    component: dashboardLayout,
    children: [
      {
        path: 'merchant',
        name: 'merchant',
        meta: {
          name: 'merchant',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: merchant
      },
      {
        path: 'add-merchant',
        name: 'addMerchant',
        meta: {
          name: 'addMerchant',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: addMerchant
      },
      {
        path: 'view-merchant/:id',
        name: 'viewMerchant',
        meta: {
          name: 'viewMerchant',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: addMerchant
      },
      {
        path: 'pending-merchant',
        name: 'pendingMerchant',
        meta: {
          name: 'pendingMerchant',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: pendingMerchant
      },
      {
        path: 'settings',
        name: 'settings',
        meta: {
          name: 'settings',
          parentName: 'settings',
          userType: '',
          permission: ''
        },
        component: settings
      },
      // {
      //   path: 'add-tax',
      //   name: 'addTax',
      //   meta: {
      //     name: 'addTax',
      //     parentName: 'marketplace',
      //     userType: '',
      //     permission: ''
      //   },
      //   component: addTax
      // },
      {
        path: 'customers',
        name: 'customers',
        meta: {
          name: 'customers',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: customers
      },
      {
        path: 'reviews',
        name: 'reviews',
        meta: {
          name: 'reviews',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: reviews
      },
      {
        path: 'orders',
        name: 'orders',
        meta: {
          name: 'orders',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: orders
      },
      {
        path: 'seller-withdrawal-requests',
        name: 'sellerWithdrawalRequests',
        meta: {
          name: 'sellerWithdrawalRequests',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: sellerWithdrawalsRequestsIndex
      },
      {
        path: 'user-withdrawal-requests',
        name: 'userWithdrawalRequests',
        meta: {
          name: 'userWithdrawalRequests',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: userWithdrawalsRequestsIndex
      },
      {
        path: 'withdrawal-requests/:id',
        name: 'viewWithRequest',
        meta: {
          name: 'viewWithRequest',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: withDrawalRequestDetails
      },
      {
        path: 'orders/:id',
        name: 'viewOrder',
        meta: {
          name: 'viewOrder',
          parentName: 'marketplace',
          userType: '',
          permission: ''
        },
        component: () => import('./views/orderProducts')
      }
    ]
  }

]
