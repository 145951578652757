export default {
  home: 'الصفحة الرئيسية',
  aboutUs: 'معلومات عنا',
  services: 'خدماتنا',
  mediaCenter: 'المركز الإعلامى',
  contactUs: 'تواصل معنا',
  shops: 'المتاجر',
  permissions: 'التصاريح',
  insurance: 'التأمين',
  transport: 'النقل',
  veterinarian: 'البيطره',
  camelTrade: 'تداول الأبل',
  events: 'الفعاليات',
  searchInAllDepartments: 'بحث في جميع الأقسام',
  footer: 'منصة إبل هي منصة متخصصة بتقديم كافة الخدمات المتعلقة بالإبل في المملكة العربية السعودية، وتشمل خدمات التأمين والنقل والتصريح، ومتاجر الأعلاف وأعذية ومستلزمات الإبل وخدمات البيطرة، كما تعتبر منصة تداول الإبل الرئيسية من بيع وشراء وتأجير ومشاركة',
  termsConditions: 'الشروط و السياسات'
}
