import validation from './validation/validation';
import auth from './auth/auth';
import endUser from './endUser/endUser';
import admins from './admins/admins';
import main from './main/main';
import insurance from './insurance/insurance';
import transportation from './transportation/transportation';
import countries from './countries/countries';
import cities from './cities/cities';
import areas from './areas/areas';
import categories from './categories/categories';
import storeCategories from '../en/storeCategories/storeCategories';
import units from './units';
import contactus from './contactus';
import shippingCompanies from './shippingCompanies/shippingCompanies';
export default {
  validation,
  endUser,
  admins,
  main,
  auth,
  insurance,
  transportation,
  countries,
  cities,
  areas,
  categories,
  storeCategories,
  units,
  contactus,
  shippingCompanies
};
