export default {
  IdNumber: 'رقم الهوية',
  password: 'الرقم السرى',
  phoneNumber: 'رقم الجوال',
  userName: 'اسم المستخدم',
  fullName: 'الاسم بالكامل',
  email: 'إيميل',
  otbCode: 'الرمز',
  check: 'تحقق',
  enter: 'دخول',
  createNewAccount: ' تسجيل حساب جديد',
  createAccount: ' تسجيل حساب',
  codeOtb: 'كود الرمز',
  otbTitle: 'تم إرسال رسالة إلى رقم الجوال الخاص بكم',
  otbInfo: 'تتضمن رمز OTB',
  continueOtb: 'المتابعة',
  create: 'تسجيل',
  enterLogin: 'تسجيل الدخول',
  dashboard: 'لوحة التحكم',
  marketplace: 'المتاجر',
  veterinary: 'البيطره',
  events: 'الفاعليات',
  news: 'الأخبار',
  images: 'الصور',
  gallery: 'معرض',
  videos: 'الفديوهات',
  video: 'فيديو',
  transactions: 'العمليات',
  payments: 'الماليات',
  website: 'الموقع',
  termsCondition: 'الشروط والأحكام',
  privacyPolicy: 'سياسة الخصوصية'
}
