export default {
  transportation: 'النقل',
  process: 'العماليات',
  companies: 'الشركات',
  drivers: 'السائقين',
  offDrivers: 'السائقين غير مفعله',
  offCompanies: 'الشركات غير مفعله',
  vehiclesType: 'اقسام الشاحنات',
  vehiclesMake: 'انواع الشاحنات',
  vehicles: 'الشاحنات',
  companySettings: 'اعدادات الشركة',
  driversSettings: 'اعدادات السائق'
}
