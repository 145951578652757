<template>
  <b-badge :class="`font-size-12 status--${filterStatus?filterStatus : 'pending' }`">
    <span v-if="status === 'processing'">
      قيد المراجعة
    </span>
    <span v-else-if="status === 'publish'">
      مكتمل
    </span>
    <span v-else-if="status === 'unpublish'">
      غير منشور
    </span>
    <span v-else-if="status === 'completed'">
      مكتمل
    </span>
    <span v-else-if="status === 'accepted'">
      مقبول
    </span>
    <span v-else-if="status === 'denied'">
      مرفوض
    </span>
    <span v-else-if="status === 'in_progress'">
      قيد المراجعة
    </span>
    <span v-else-if="status === 'published'">
      تم النشر
    </span>
    <span v-else-if="status == 'active'">
      مفعل
    </span>
    <span v-else>
      معلق
    </span>
  </b-badge>
</template>

<script>
export default {
  name: 'MainStatus',
  props: {
    status: {
      type: String
    }
  },
  /*  filters: {
    removeSpace(value) {status
      return value.toLowerCase().replace(' ', '_') || this.data.toLowerCase().replaceAll(' ', '_')
    },
  }, */
  computed: {
    filterStatus () {
      return this.status ? this.status.toLowerCase().replace(' ', '_') || this.data.toLowerCase().replaceAll(' ',
        '_') : ''
    }
  }
}
</script>
