import * as rules from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import i18n from '../i18n'

Object.keys(rules).forEach(rule => {
  if (rule === 'required') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.required') })
  } else if (rule === 'email') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.email') })
  } else if (rule === 'numeric') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.numeric') })
  } else if (rule === 'min') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.min', { num: '{length}' }) })
  } else if (rule === 'max') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.max', { num: '{length}' }) })
  } else if (rule === 'length') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.length', { num: '{length}' }) })
  } else if (rule === 'digits') {
    extend(rule, { ...rules[rule], message: i18n.t('validation.digits', { num: '{length}' }) })
  } else {
    extend(rule, { ...rules[rule], message: i18n.t('validation.notValid') })
  }
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
