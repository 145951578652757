export default {
  english: 'الأنجليزية',
  arabic: 'العربية',
  login: 'تسجيل الدخول',
  cart: 'سلة المشتريات',
  save: 'حفظ',
  add: 'إضافة',
  update: 'تعديل',
  view: 'عرض',
  status: 'الحالة',
  cancel: 'إلغاء',
  loading: 'تحميل',
  addedSuccessfully: 'تمت الإضافة بنجاح',
  updatedSuccessfully: 'تم التعديل بنجاح',
  name: 'الاسم',
  title: 'العنوان',
  code: 'الكود',
  image: 'الصورة',
  flag: 'العلم',
  actions: 'التحكم',
  landing: 'الصفحات',
  termsAndDetails: 'قراءة الشروط والتفاصيل',
  stores: 'المتاجر',
  pendingStores: 'المتاجر المعلقة',
  tax: 'الضريبة',
  subject: 'الموضوع',
  message: 'الرسالة',
  settings: 'الإعدادات',
  twitter: 'تويتر',
  facebook: 'فيس بوك',
  instagram: 'انستجرام',
  customers: 'العملاء',
  reviews: 'التعليقات',
  orders: 'الطلبات',
  sellerWithdrawalsRequests: 'طلبات سحب التجار',
  userWithdrawalsRequests: 'طلبات سحب المستخدمين',
  delete: {
    delete: 'مـسح',
    areYouSureToDeleteThisItem: 'هل متأكد أنك تريد مسح هذا العنصر؟',
    sureDelete: 'بالتأكيد، مـسح'
  },
  pagination: {
    page: 'صفحة',
    of: 'من',
    items: 'صفوف',
    itemsPerPage: 'الصفوف لكل صفحة'
  },
  description: 'الوصف',
  description_en: 'الوصف بالانجليزية',
  description_ar: 'الوصف بالعربية',
  name_en: 'الاسم بالانجليزية',
  name_ar: 'الاسم بالعربية',
  phone: 'الهاتف',
  national_id: 'الرقم القومي',
  amount: 'المبلغ',
  email: 'البريد الالكتروني',
  created_at: 'تاريخ الإنشاء',
  updated_at: 'تاريخ التعديل',
  invoiceImage: 'صورة الفاتورة'
}
