import axios from 'axios';
import { apiHeader, apiResponseCatch, marketPlace, website } from '@/axios';

export default {
  listDataTabl (url, filter, baseURL = '') {
    const apiClient = axios.create({
      baseURL: baseURL,
      headers: apiHeader()
    });
    apiClient.interceptors.response.use((response) => {
      return response;
    }, apiResponseCatch);
    return apiClient.get(url, {
      params: filter
    });
  },
  addImage (payload, config) {
    return marketPlace().post('add-image', payload, config);
  },
  removeImage (id, type) {
    return marketPlace().get(`remove-image/${ id }/${ type }`);
  },
  removeRow (baseURL, url, rowId) {
    const apiClient = axios.create({
      baseURL: baseURL,
      headers: apiHeader()
    });
    apiClient.interceptors.response.use((response) => {
      return response;
    }, apiResponseCatch);
    return apiClient.delete(`${ baseURL }${ url }/${ rowId }`);
  },
  changeStatus (baseURL = '', url, id) {
    console.log(baseURL, url, id);
    const apiClient = axios.create({
      baseURL: baseURL,
      headers: apiHeader()
    });
    apiClient.interceptors.response.use((response) => {
      return response;
    }, apiResponseCatch);
    return apiClient.patch(`${ url }/${ id }`);
  },
  getAllCities () {
    return marketPlace().get('cities');
  },
  getAllContries () {
    return marketPlace().get('countries');
  },
  getAllAreas (id = null) {
    return marketPlace().get('areas', {
      params: {
        city_id: id
      }
    });
  },
  commonUploadFiles (payload, config, baseURL = 'upload') {
    return website().post(baseURL, payload, config);
  },
  commonUploadImages (payload, config, baseURL = 'upload') {
    return website().post(baseURL, payload, config);
  }
};
