<template>
  <b-row>
    <b-col md="6" class="mb-3">
      <input-form :placeholder="`${placeholder} (${$t('main.arabic')})`" :label="`${label} (${$t('main.arabic')})`" v-model="arrayOfTranslation[0].name" :disabled="disabled"></input-form>
    </b-col>
    <b-col md="6" class="mb-3">
      <input-form :placeholder="`${placeholder} (${$t('main.english')})`" :label="`${label} (${$t('main.english')})`" v-model="arrayOfTranslation[1].name" :disabled="disabled"></input-form>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    arrayOfTranslation: { type: Array, default: () => [] },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    validate: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    test (t, y) {
      console.log(t, y)
    }
  }
}
</script>
