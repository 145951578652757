export default {
  english: 'English',
  arabic: 'Arabic',
  login: 'Login',
  cart: 'Cart',
  save: 'Save',
  add: 'Add',
  update: 'Update',
  view: 'View',
  cancel: 'Cancel',
  loading: 'Loading',
  addedSuccessfully: 'Added Successfully',
  updatedSuccessfully: 'Updated Successfully',
  name: 'Name',
  status: 'Status',
  title: 'Title',
  code: 'Code',
  image: 'Image',
  flag: 'Flag',
  actions: 'Actions',
  landing: 'landing',
  stores: 'stores',
  pendingStores: 'pending stores',
  tax: 'tax',
  settings: 'settings',
  customers: 'customers',
  subject: 'Subject',
  message: 'Message',
  reviews: 'reviews',
  orders: 'orders',
  twitter: 'twitter',
  facebook: 'facebook',
  instagram: 'instagram',
  sellerWithdrawalsRequests: 'Seller withdrawals requests',
  userWithdrawalsRequests: 'User withdrawals requests',
  delete: {
    delete: 'Delete',
    areYouSureToDeleteThisItem: 'Are you sure to delete this item?',
    sureDelete: 'Sure, Delete'
  },
  pagination: {
    page: 'Page',
    of: 'of',
    items: 'Items',
    itemsPerPage: 'Items per page'
  },
  description: 'Description',
  description_en: 'Description in English',
  description_ar: 'Description in Arabic',
  name_en: 'Name in English',
  name_ar: 'Name in Arabic',
  phone: 'Phone',
  national_id: 'National ID',
  amount: 'Amount',
  email: 'Email',
  created_at: 'Created At',
  updated_at: 'Updated At',
  invoiceImage: 'Invoice Image'
}
