export default {
  methods: {
    hasPer: (permissions) => {
      // const authUser = JSON.parse(localStorage.getItem('userInfo'))
      // if (permissions === 'all') {
      //   return true
      // } else {
      //   if (authUser.user.permissions) {
      //     return authUser.user.permissions.some(permission => permissions.includes(permission))
      //   }
      // }
      return false
    },
    isType: () => true,
    isAdmin: () => true
  }
}
