const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const cities = () => import('./views/index')
const item = () => import('./views/item')
export default [
  {
    path: '/cities',
    name: 'cities',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'cities',
        meta: {
          name: '',
          parentName: '',
          userType: '',
          permission: ''
        },
        component: cities
      },
      {
        path: 'add',
        name: 'cities.add',
        meta: {
          name: 'cities.add',
          parentName: 'cities',
          userType: '',
          permission: ''
        },
        component: item
      },
      {
        path: 'update/:id?',
        name: 'cities.update',
        meta: {
          name: 'cities.update',
          parentName: 'cities',
          userType: '',
          permission: ''
        },
        component: item,
        props: true
      },
      {
        path: 'view/:id?',
        name: 'cities.view',
        meta: {
          name: 'cities.view',
          parentName: 'cities',
          userType: '',
          permission: ''
        },
        component: item,
        props: true
      }
    ]
  }
]
