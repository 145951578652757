import { marketPlace } from '@/axios'

export default {
  acceptMerchent (storeId, payload) {
    return marketPlace().patch(`admin/stores/${storeId}/accept`, payload)
  },
  addMerchent (payload) {
    return marketPlace().post('admin/merchants', payload)
  },
  getCities () {
    return marketPlace().get('cities')
  },
  addTax (payload) {
    return marketPlace().post('admin/taxes', payload)
  },
  getStore (id) {
    return marketPlace().get(`admin/stores/${id}`)
  },
  getSettings () {
    return marketPlace().get('admin/app-settings')
  },
  saveSettings (payload) {
    return marketPlace().patch('admin/app-settings', payload)
  },
  getWithDrawalRequestDetails (id) {
    return marketPlace().get(`admin/wallets/withdraw-requests/${id}`)
  },
  updateWithDrawalRequest (id, payload) {
    return marketPlace().patch(`admin/wallets/withdraw-requests/${id}`, payload)
  },
  saveStore (payload) {
    return marketPlace().post('admin/signup/merchant', payload)
  },
  updateCommission (payload, id) {
    return marketPlace().patch(`admin/stores/${id}`, payload)
  },
  getOrder (id) {
    return marketPlace().get(`admin/orders/${id}`)
  }
}
