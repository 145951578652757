const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue');

const shipping = () => import('./views/index');
const item = () => import('./views/item');
export default [
  {
    path: '/shipping-companies/',
    name: 'shipping-companies',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'shipping-companies-list',
        meta: {
          name: '',
          parentName: '',
          userType: '',
          permission: ''
        },
        component: shipping
      },
      {
        path: 'add',
        name: 'company.add',
        meta: {
          name: 'company.add',
          title: 'shippingCompanies.add',
          parentName: 'shipping-companies',
          userType: '',
          permission: ''
        },
        component: item
      },
      {
        path: 'update/:id?',
        name: 'company.update',
        meta: {
          name: 'company.update',
          title: 'shippingCompanies.update',
          parentName: 'shipping-companies',
          userType: '',
          permission: ''
        },
        component: item,
        props: true
      },

    ]
  }
];
